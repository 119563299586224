import React from 'react';
import Home from './Pages/HomePage/Home'; 

function App() {
  return (
    <div className="app">
      <Home />
    </div>
  );
}

export default App;

