import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import logo from "./assets/ebuAI (1).png";
import getStarted from "./assets/Group.png";
import partener_logo from "./assets/Mask group.png";
import group from "./assets/Group (1).png";
import FAQ from "./assets/Frame 4.png";
import settings from "./assets/Frame 6.png";
import Logs from "./assets/Frame 5.png";
import send from "./assets/Group (2).png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import bag from "./assets/Frame 7.png";
import smilie from "./assets/Group (4).png";
import Price from "./assets/Group (5).png";
import Discount from "./assets/Group (6).png";
import Results from "./assets/Group (8).png";

const Home = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [messages, setMessages] = useState([]);
  const [showConversation, setShowConversation] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state for spinner
  const chatEndRef = useRef(null); // Reference to the end of chat

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
    document
      .querySelector(".container")
      .classList.toggle("darken-background", !sidebarVisible);
  };

  const handleBackgroundClick = (e) => {
    if (sidebarVisible && e.target.classList.contains("darken-background")) {
      toggleSidebar();
    }
  };

  const handleSendClick = async () => {
    if (!query) {
      alert("Please enter a query.");
      return;
    }

    setShowConversation(true);
    setLoading(true); // Start loading

    try {
      const response = await fetch(
        `https://api.buai.co/get_response/?id_number=${messages.length + 1}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      setMessages([
        ...messages,
        { text: query, sender: "user" },
        { text: data.response, sender: "bot" }
      ]);
      setQuery("");
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setMessages([
        ...messages,
        { text: query, sender: "user" },
        { text: "An error occurred while sending the request.", sender: "bot" }
      ]);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Handle pressing Enter to send the message
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendClick();
    }
  };

  // Use useEffect to handle auto-scrolling
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]); // Depend on messages to trigger scroll when messages update

  return (
    <div
      className={`container ${sidebarVisible ? "darken-background" : ""}` }
      style={{ cursor: 'pointer' }}
      onClick={handleBackgroundClick}
    >
      <div className={`sidebar ${sidebarVisible ? "visible" : "hidden"}`} style={{ cursor: 'pointer' }}      >
        <div className="logo">
          <img src={logo} alt="ebuAI Logo" />
          <p style={{ color: "#737373", fontSize: "12px", fontWeight: "350" }}>
            App Version 1.0.0
          </p>
        </div>
        <div className="main">
          <p>Main</p>
        </div>
        <ul className="ul1">
          <li>
            <img src={getStarted} alt="Get Started" />
            Get Started
          </li>
          <li>
            <img src={partener_logo} alt="Our Partners" />
            Our Partners
          </li>
          <li>
            <img src={group} alt="About" />
            About
          </li>
        </ul>
        <p className="setting">Settings</p>
        <hr />
        <ul className="ul2">
          <li>
            <img src={FAQ} alt="FAQs" />
          </li>
          <li>
            <img src={settings} alt="Settings" />
          </li>
          <li>
            <img src={Logs} alt="Logout" />
          </li>
        </ul>
      </div>
      <div className="main-content">
        <div className="top-bar">
          <FontAwesomeIcon
            icon={faBars}
            className="hamburger-icon"
            onClick={toggleSidebar}
          />
          <div className="social-icons">
            <i className="fab fa-twitter"></i>
            <i className="fab fa-instagram"></i>
            <i className="fab fa-facebook-f"></i>
          </div>
        </div>
        <div
          className={`big-box ${sidebarVisible ? "darken-background" : ""}`}
          onClick={handleBackgroundClick}
        >
          {!showConversation ? (
            <>
              <img style={{ width: "70px" }} src={bag} alt="img missing" />
              <h2>Welcome to BuAI</h2>
              <p style={{ color: "#5E5E5E" }}>
                Introducing BuAI, your free AI shopping assistant! No more endless
                scrolling - just compare prices from different stores in seconds.
                Let BuAI do the hard work and find the best deals for you. Just say,
                "Donuts!"
              </p>
              <ul className="features">
                <li className="assistant">
                  <img
                    style={{ marginTop: "2px" }}
                    src={smilie}
                    alt="shopping Assistant"
                  />
                  AI shopping Assistant
                </li>
                <li className="assistant">
                  <img
                    style={{ marginTop: "2px" }}
                    src={Price}
                    alt="Price Comparisons"
                  />
                  Price Comparisons
                </li>
                <li className="assistant">
                  <img
                    style={{ marginTop: "2px" }}
                    src={Discount}
                    alt="Discount Hunter"
                  />
                  Discount Hunter
                </li>
                <li className="assistant">
                  <img
                    style={{ marginTop: "2px" }}
                    src={Results}
                    alt="Real-Time Results"
                  />
                  Real-Time Results
                </li>
              </ul>
            </>
          ) : (
            <div className="chat-container">
              <div className="chat-box">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`chat-message ${message.sender}-message`}
                  >
                    {message.text}
                  </div>
                ))}
                {/* Invisible element to always scroll to */}
                <div ref={chatEndRef} />
                {loading && <div className="loader">Loading...</div>} {/* Loader */}
              </div>
            </div>
          )}
        </div>
        <div
          className={`input-container ${
            sidebarVisible ? "darken-background" : ""
          }`}
          onClick={handleBackgroundClick}
        >
          <input
            className={`type-something ${
              sidebarVisible ? "darken-background" : ""
            }`}
            type="text"
            placeholder="Type something..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyPress={handleKeyPress} // Handle Enter key
          />
          <img
            src={send}
            alt="Send"
            className="send-icon"
            onClick={handleSendClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
